import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import { downloadBlob, processProgressRequest } from 'lib/util';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';
import ModalStatus from 'materials/ModalStatus';
import CustomerReportComponent from 'module/Report/Component/CustomerReport/CustomerReportComponent';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import {
  getCustomReportFile,
  getCustomStatusSetting,
} from 'services/CustomerService';
import {
  getCustomerList,
  getProductListByCustomer,
} from 'services/GeneralService';
import { downloadCustomerReport } from 'services/ReportCustomerServices';

export default function CustomerReportContainer() {
  const [isCollapse, setIsCollapse] = React.useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    number | undefined
  >(undefined);
  const formRef: any = React.useRef();

  const { data: dataCustomers, isFetching: isFetchingCustomers } = useQuery(
    ['dataCustomers'],
    getCustomerList,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataProducts, isFetching: isFetchingProducts } = useQuery(
    ['dataProducts', selectedCustomerId],
    getProductListByCustomer,
    {
      refetchOnWindowFocus: false,
      enabled: selectedCustomerId !== undefined,
      onError: error => {
        console.log('error', error);
      },
    }
  );
  const { data: dataCustomReportFile, isFetching: isFetchingCustomReportFile } =
    useQuery(
      ['dataCustomReportFile', selectedCustomerId, 'Active'],
      getCustomReportFile,
      {
        refetchOnWindowFocus: false,
        enabled: selectedCustomerId !== undefined,
        onError: error => {
          console.log('error', error);
        },
      }
    );

  const {
    data: dataCustomSettingSuccess,
    isFetching: isFetchingCustomSettingSuccess,
  } = useQuery(
    ['getCustomStatusSetting', selectedCustomerId, 'Success'],
    getCustomStatusSetting,
    {
      refetchOnWindowFocus: false,
      enabled: selectedCustomerId !== undefined,
      onSuccess(data) {
        if (data.length > 0) {
          const allDescription: string[] = data.map(
            (item: any) => item.description
          );
          formRef.current.change('statusSuccess', allDescription);
        } else {
          formRef.current.change('statusSuccess', []);
        }
      },
    }
  );
  const {
    data: dataCustomSettingReturn,
    isFetching: isFetchingCustomSettingReturn,
  } = useQuery(
    ['getCustomStatusSetting', selectedCustomerId, 'Return'],
    getCustomStatusSetting,
    {
      refetchOnWindowFocus: false,
      enabled: selectedCustomerId !== undefined,
      onSuccess(data) {
        if (data.length > 0) {
          const allDescription: string[] = data.map(
            (item: any) => item.description
          );
          formRef.current.change('statusReturn', allDescription);
        } else {
          formRef.current.change('statusReturn', []);
        }
      },
    }
  );
  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation((data: any) => downloadCustomerReport(data, progressHandler), {
      onSuccess: (res: any, variables: any) => {
        const { month, date, customReportFileId, groupFileName } = variables;
        console.log('variables', variables);

        const fileExtension = dataCustomReportFile.find(
          (item: any) => item.id === customReportFileId
        ).format;
        const customerName = dataCustomers.find(
          (item: any) => item.id === selectedCustomerId
        ).name;

        if (res.status === 200) {
          const formats = {
            EXCEL: 'xlsx',
            CSV: 'csv',
            TXT: 'txt',
          };
          const formatFile = formats[fileExtension.toUpperCase()] || '';
          const isMontly = month ? 'Bulanan' : 'Harian';
          const dateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
          const baseFileName = `Laporan ${customerName} ${isMontly} ${date} ${dateNow}`;

          downloadBlob(
            res.data,
            baseFileName,
            groupFileName ? 'zip' : formatFile
          );
          ModalStatus({
            status: 'success',
            title: 'Download berhasil',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
          setLoadingProgress(0);
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handleDownloadReport = (values: any) => {
    const {
      customerId,
      customReportFileId,
      filterDateType,
      date,
      month,
      customerProducts,
      deliveryStats,
      treatmentDocument,
      fileString,
      coloumn,
      dataMatchingColumnType,
      statusSuccess,
      statusReturn,
      deliveryType,
      groupFileName,
      deliveryDetail,
      today,
    } = values;
    const cycleDate = moment(date).format(month ? 'YYYYMM' : 'YYYYMMDD');

    const payload: any = {
      customerId,
      customReportFileId,
      filterDateType,
      date: cycleDate,
      month,
      customerProducts,
      deliveryStats,
      treatmentDocument,
      fileString,
      coloumn: Number(coloumn) || 0,
      dataMatchingColumnType,
      statusSuccess,
      statusReturn,
      deliveryType: filterDateType === 'Manifest' ? [] : deliveryType,
      groupFileName,
      deliveryDetail,
      today,
    };

    mutateDownloadFile(payload);
  };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  const listCustomer =
    dataCustomers &&
    dataCustomers.length > 0 &&
    dataCustomers.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
  const listProduct =
    dataProducts &&
    dataProducts.length > 0 &&
    dataProducts.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
  const listCustomReportFile =
    dataCustomReportFile &&
    dataCustomReportFile.length > 0 &&
    dataCustomReportFile.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
  const listCustomerSettingSuccess =
    dataCustomSettingSuccess &&
    dataCustomSettingSuccess.length > 0 &&
    dataCustomSettingSuccess.map((item: any) => ({
      id: item.description,
      name: item.description,
    }));
  const listCustomerSettingReturn =
    dataCustomSettingReturn &&
    dataCustomSettingReturn.length > 0 &&
    dataCustomSettingReturn.map((item: any) => ({
      id: item.description,
      name: item.description,
    }));
  return (
    <>
      <CustomerReportComponent
        isCollapse={isCollapse}
        handleCollapse={handleCollapse}
        handleDownloadReport={handleDownloadReport}
        isLoading={
          isLoadingDownload ||
          isFetchingCustomers ||
          isFetchingProducts ||
          isFetchingCustomSettingSuccess ||
          isFetchingCustomSettingReturn ||
          isFetchingCustomReportFile
        }
        listCustomer={listCustomer || []}
        listProduct={listProduct || []}
        listCustomReportFile={listCustomReportFile || []}
        listCustomerSettingSuccess={listCustomerSettingSuccess || []}
        listCustomerSettingReturn={listCustomerSettingReturn || []}
        setSelectedCustomerId={setSelectedCustomerId}
        formRef={formRef}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </>
  );
}

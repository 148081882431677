import React from 'react';
import CourierBonusReportComponent from '../Component/CourierBonusReportComponent';
import {
  getAllBranch,
  getAllCustomers,
  getProductListByCustomer,
} from 'services/GeneralService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCourierBranch } from 'services/CourierService';
import moment from 'moment-timezone';
import { mutateDownloadRekap } from 'services/ReportCustomerServices';
import { downloadBlob } from 'lib/util';
import { Modal } from 'antd';

export default function CourierBonusReportContainer() {
  const [branch, setBranch] = React.useState<number | undefined>(undefined);
  const [customerId, setCustomerId] = React.useState<number | undefined>(
    undefined
  );

  const { data: dataCustomers, isFetching: isFetchingCustomers } = useQuery({
    queryKey: ['GetListCustomer'],
    queryFn: getAllCustomers,
  });
  const { data: dataBranchs, isFetching: isFetchingBranch } = useQuery({
    queryKey: ['getAllBranch'],
    queryFn: getAllBranch,
  });
  const { data: dataCourierBranch, isFetching: isFetchingCourier } = useQuery({
    queryKey: ['getCourierBranch', branch, true],
    queryFn: getCourierBranch,
    enabled: branch !== undefined,
  });
  const { data: dataProducts, isFetching: isFetchingProducts } = useQuery({
    queryKey: ['getProductListByCustomer', customerId],
    queryFn: getProductListByCustomer,
    enabled: customerId !== undefined,
  });

  const mutateDownloadReport = useMutation({
    mutationFn: mutateDownloadRekap,
    onSuccess(data, variables, context) {
      const { startDate, endDate, typeReportBonus } = variables;
      downloadBlob(
        data.data,
        `Laporan Bonus ${typeReportBonus} ${moment(startDate).format(
          'YYYY-MM-DD'
        )} ${moment(endDate).format('YYYY-MM-DD')}`,
        'xls'
      );
    },
    onError() {
      Modal.error({
        title: <b style={{ color: '#EA1A1A' }}>Gagal menjalankan proses</b>,
        content: (
          <p style={{ color: '#C0C0C0' }}>
            Terjadi kesalahan pada server, mohon hubungi admin untuk tindakan
            lebih lanjut
          </p>
        ),
      });
    },
  });

  const handleDownload = values => {
    const payload = {
      ...values,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
    };
    mutateDownloadReport.mutate(payload);
  };

  const listCustomer =
    dataCustomers?.data?.data?.map((item: any) => ({
      id: item.id,
      name: item.name,
    })) || [];

  const listBranch =
    dataBranchs?.data?.map((item: any) => ({
      id: item.id,
      name: `${item.name} (${item.uniqueCode})`,
    })) || [];

  const listCourier =
    dataCourierBranch?.map((item: any) => ({
      id: item.id,
      name: `${item.firstName} (${item.code})`,
    })) || [];

  const listProducts =
    dataProducts?.map((item: any) => ({
      id: item.id,
      name: item.name,
    })) || [];

  return (
    <CourierBonusReportComponent
      setBranch={setBranch}
      setCustomerId={setCustomerId}
      isLoading={
        isFetchingCustomers ||
        isFetchingBranch ||
        isFetchingCourier ||
        isFetchingProducts ||
        mutateDownloadReport.isLoading
      }
      listCustomer={listCustomer}
      listBranch={listBranch}
      listCourier={listCourier}
      listProducts={listProducts}
      handleDownload={handleDownload}
    />
  );
}

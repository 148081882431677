import React, { useEffect, useState } from 'react';

import UpdateAddressComponent from '../Component/UpdateAddressComponent';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  getDeliveryDataChangeAddress,
  mutateChangeDeliveryAddress,
  mutateDownloadSampleFile,
  mutateUploadDownloadResult,
  mutateUploadDownloadResultSingleUpdate,
  mutateUploadSingleUpdateAddress,
} from 'services/ChangeAddressServices';
import { Alert, Button, Col, Modal, Row } from 'antd';
import {
  getAllCustomers,
  getProductListByCustomer,
} from 'services/GeneralService';
import { getCustomerDetailCsvList } from 'services/CustomerService';
import moment from 'moment-timezone';
import CIcon from 'apps/assets/components/CIcon';
import { downloadBlob } from 'lib/util';
import ModalStatus from 'materials/ModalStatus';

const UpdateAddressContainer = () => {
  const [waybill, setWaybill] = useState<any>('');
  const [dataDelivery, setDataDelivery] = useState<any>(undefined);
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    number | undefined
  >(undefined);

  const { isFetching: isFetchingDelivery, refetch: refetchDelivery } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['getDeliveryDataChangeAddress'],
    queryFn: () =>
      getDeliveryDataChangeAddress({
        queryKey: ['getDeliveryDataChangeAddress', waybill],
      }),
    enabled: false,

    onSuccess(data) {
      setDataDelivery(data);
    },
  });

  const { data: dataCustomers, isFetching: isFetchingCustomers } = useQuery({
    queryKey: ['GetListCustomer'],
    queryFn: getAllCustomers,
  });

  const { data: dataProducts, isFetching: isFetchingProducts } = useQuery({
    queryKey: ['getProductListByCustomerId', selectedCustomerId],
    queryFn: getProductListByCustomer,
    enabled: !!selectedCustomerId,
  });

  const { data: dataUploadTemplate, isFetching: isFetchingTemplate } = useQuery(
    {
      queryKey: ['getCustomerDetailCsvList', selectedCustomerId],
      queryFn: () =>
        getCustomerDetailCsvList({
          IdCustomer: selectedCustomerId,
          isActiveType: 'Active',
        }),
      enabled: !!selectedCustomerId,
    }
  );

  const mutateDownloadResult = useMutation({
    mutationFn: mutateUploadDownloadResult,
    onSuccess(data) {
      downloadBlob(
        data.data,
        `ResultUpdateAddress_${moment().format('DDMMYYYY')}`,
        'txt'
      );
    },
    onError() {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan',
      });
    },
  });
  const mutateDownloadResultSingleUpdate = useMutation({
    mutationFn: mutateUploadDownloadResultSingleUpdate,
    onSuccess(data) {
      downloadBlob(
        data.data,
        `Gagal patokan ${moment().format('yyyyMMddhhmmss')}`,
        'txt'
      );
    },
    onError() {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan',
      });
    },
  });

  const mutateChangeAddress = useMutation({
    mutationFn: mutateChangeDeliveryAddress,
    onSuccess(data: any, variables: any) {
      const { formatType } = variables;
      const { success, failed, listResultCsv } = data?.data;

      setWaybill('');
      setDataDelivery(undefined);

      if (formatType === 'single') {
        Modal.success({
          title: (
            <p style={{ color: '#31E116' }}>
              <b>Sinkronisasi data berhasil</b>
            </p>
          ),
        });
        refForm.current.reset();
      } else if (formatType === 'multiple') {
        Modal.info({
          title: (
            <p style={{ color: 'rgb(17, 190, 255)' }}>
              <b>STATUS PERBAHARUI ALAMAT</b>
            </p>
          ),
          content: (
            <div className="defaultPaddingModal">
              <Row>
                <Col span={12} className="spacingRightAlertCallCenter">
                  <Alert
                    className="alertSuccess"
                    message={
                      <React.Fragment>
                        <Col span={6}>
                          <CIcon
                            className={'iconNotifSuccess'}
                            type="check-square"
                          />
                        </Col>
                        <Col span={18} className="greenText spacingTop">
                          <Row>
                            Berhasil : <b>{success}</b>
                          </Row>
                        </Col>
                      </React.Fragment>
                    }
                    type="success"
                  />
                </Col>
                <Col span={12} className="spacingLeftAlertCallCenter">
                  <Alert
                    className="alertSuccess"
                    message={
                      <React.Fragment>
                        <Col span={6}>
                          <CIcon
                            className={'iconNotifFailed'}
                            type="close-square"
                          />
                        </Col>
                        <Col span={18} className="redText spacingTop">
                          <Row>
                            Gagal : <b>{failed}</b>
                          </Row>
                        </Col>
                      </React.Fragment>
                    }
                    type="error"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12} className="spacingRight">
                  <Button
                    disabled={failed === 0 ? true : false}
                    className="btnDownloadResultDelete"
                    onClick={() => {
                      mutateDownloadResult.mutate({
                        listResult: listResultCsv,
                      });
                    }}
                    type="primary"
                    shape="round"
                  >
                    Download
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    disabled={false}
                    className="btnCloseResultDelete marginLeft"
                    onClick={() => Modal.destroyAll()}
                    type="primary"
                    shape="round"
                  >
                    Tutup
                  </Button>
                </Col>
              </Row>
            </div>
          ),
          width: '60%',
          okButtonProps: {
            style: {
              display: 'none',
            },
          },
        });
        refForm.current.reset();
        refForm.current.change('formatType', 'multiple');
      }
    },
    onError(error: any) {
      Modal.error({
        title: <b style={{ color: '#EA1A1A' }}>Gagal menjalankan proses</b>,
        content: <p>{error.response.data?.Message}</p>,
      });
    },
  });

  const mutateUploadSingleUpdate = useMutation({
    mutationFn: mutateUploadSingleUpdateAddress,
    onSuccess(data: any, variables: any) {
      const { successCount, failedCount, failedUpdateReportFile } = data?.data;
      const fileName =
        failedUpdateReportFile?.split('/')[
          failedUpdateReportFile?.split('/')?.length - 1
        ];
      Modal.info({
        title: (
          <p style={{ color: 'rgb(17, 190, 255)' }}>
            <b>STATUS UPLOAD FILE(S) PATOKAN WILAYAH</b>
          </p>
        ),
        content: (
          <div className="defaultPaddingModal">
            <Row>
              <Col span={12} className="spacingRightAlertCallCenter">
                <Alert
                  className="alertSuccess"
                  message={
                    <React.Fragment>
                      <Col span={6}>
                        <CIcon
                          className={'iconNotifSuccess'}
                          type="check-square"
                        />
                      </Col>
                      <Col span={18} className="greenText spacingTop">
                        <Row>
                          Berhasil : <b>{successCount}</b>
                        </Row>
                      </Col>
                    </React.Fragment>
                  }
                  type="success"
                />
              </Col>
              <Col span={12} className="spacingLeftAlertCallCenter">
                <Alert
                  className="alertSuccess"
                  message={
                    <React.Fragment>
                      <Col span={6}>
                        <CIcon
                          className={'iconNotifFailed'}
                          type="close-square"
                        />
                      </Col>
                      <Col span={18} className="redText spacingTop">
                        <Row>
                          Gagal : <b>{failedCount}</b>
                        </Row>
                      </Col>
                    </React.Fragment>
                  }
                  type="error"
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  disabled={failedCount === 0 ? true : false}
                  onClick={() => {
                    mutateDownloadResultSingleUpdate.mutate(fileName);
                  }}
                  type="primary"
                  shape="round"
                >
                  Download Keterangan Gagal
                </Button>
              </Col>
              <Col span={12} style={{ textAlign: 'left' }}>
                <Button
                  disabled={false}
                  onClick={() => Modal.destroyAll()}
                  type="primary"
                  shape="round"
                >
                  OK
                </Button>
              </Col>
            </Row>
          </div>
        ),
        width: '60%',
        okButtonProps: {
          style: {
            display: 'none',
          },
        },
      });
      refForm.current.reset();
    },
    onError(error: any) {
      Modal.error({
        title: <b style={{ color: '#EA1A1A' }}>Gagal menjalankan proses</b>,
        content: <p>{error.response.data?.Message}</p>,
      });
    },
  });

  const mutateDownloadSample = useMutation({
    mutationFn: mutateDownloadSampleFile,
    onSuccess(data) {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Unggah Patokan.xlsx'); // Nama file
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    onError() {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan',
      });
    },
  });

  const handleUploadSingleUpdate = (values: any) => {
    const { file } = values;
    const payload: any = {
      file,
    };
    mutateUploadSingleUpdate.mutate(payload);
  };

  const handleSearchWaybill = () => {
    refetchDelivery();
  };
  const handleReset = (formatType: any) => {
    setDataDelivery(undefined);
    setWaybill('');
    refForm.current.reset();
  };

  const refForm = React.useRef<any>(null);

  useEffect(() => {
    if (dataDelivery) {
      refForm.current.change('shipToName', dataDelivery?.shipToName);
      refForm.current.change('shipToPhone', dataDelivery?.shipToPhone);
      refForm.current.change(
        'address',
        JSON.parse(dataDelivery?.shipToAddress)
      );
    }
    //eslint-disable-next-line
  }, [dataDelivery]);

  const handleSubmit = values => {
    const {
      waybill,
      address,
      customFileId,
      customerProductId,
      fileName,
      fileBase64,
      cycleDate,
      formatType,
    } = values;
    const payload =
      values.formatType === 'single'
        ? {
            waybill,
            shipToAddress: JSON.stringify(address),
            formatType,
          }
        : values.formatType === 'multiple'
        ? {
            customFileId,
            customerProductId,
            cycle: moment(cycleDate).format('YYYYMMDD'),
            fileName,
            fileBase64,
            formatType,
          }
        : {};
    mutateChangeAddress.mutate(payload);
  };

  const listCustomer =
    dataCustomers?.data?.data?.map((item: any) => ({
      id: item.id,
      name: item.name,
    })) || [];
  const listProducts =
    dataProducts?.map((item: any) => ({
      id: item.id,
      name: item.name,
      productType: item.productType,
    })) || [];
  const listTemplateUpload =
    dataUploadTemplate?.data?.map((item: any) => ({
      id: item.id,
      name: item.nameCustomCsv,
    })) || [];

  const handleDownloadSample = () => {
    mutateDownloadSample.mutate();
  };

  return (
    <React.Fragment>
      <UpdateAddressComponent
        handleSearchWaybill={handleSearchWaybill}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleDownloadSample={handleDownloadSample}
        setWaybill={setWaybill}
        dataDelivery={dataDelivery}
        refForm={refForm}
        isLoading={
          isFetchingDelivery ||
          mutateChangeAddress.isLoading ||
          isFetchingCustomers ||
          isFetchingProducts ||
          isFetchingTemplate ||
          mutateDownloadResult.isLoading ||
          mutateUploadSingleUpdate.isLoading
        }
        setSelectedCustomerId={setSelectedCustomerId}
        listCustomer={listCustomer}
        listProducts={listProducts}
        listTemplateUpload={listTemplateUpload}
        handleUploadSingleUpdate={handleUploadSingleUpdate}
      />
    </React.Fragment>
  );
};

export default UpdateAddressContainer;

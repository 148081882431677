import { UseBaseQueryOptions } from '@tanstack/react-query';
import instance from 'config/interceptor';

export const getCourierBranch = async (params: UseBaseQueryOptions) => {
  const [, branch, isAll] = params.queryKey || [];
  const { data } = await instance.get(
    `courier/branch/${branch}?isAll=${isAll}`
  );
  return data.data;
};

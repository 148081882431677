// DEV CHANGES ON 21 JANUARY 2025
import React from 'react';
import styles from './FilterTable.module.css';
import { Row, Col } from 'antd';
import { Form, Field } from 'react-final-form';

import SelectField from 'components/SelectField';
import RadioField from 'components/RadioField';
import InputDatePicker from 'components/InputDatePicker';
import Button from 'components/Button';
import CardMaterial from 'materials/CardMaterial';
import moment from 'moment-timezone';
import { FilterDataSlaProps } from 'module/Report/Ringkasan/container/SlaPengiriman';

type FilterTableProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  roleAccount: string;
  selectedBranchId: number | null | undefined;
  dataFilterSla: FilterDataSlaProps;
  handleResetFilter: () => void;
  handleChangeFilter: (name: string, value: any) => void;
  handleSubmitFilter: (value: FilterDataSlaProps) => void;
  listCustomer: Array<any>;
  isLoadingCustomer: boolean;
  setSelectedCustomerId: any;
  listProduct: Array<any>;
  isLoadingProduct: boolean;
};

export default function FilterTable({
  dataAllBranch,
  isLoadingBranch,
  roleAccount,
  selectedBranchId,
  dataFilterSla,
  handleResetFilter,
  handleChangeFilter,
  handleSubmitFilter,
  listCustomer,
  isLoadingCustomer,
  setSelectedCustomerId,
  listProduct,
  isLoadingProduct,
}: FilterTableProps) {
  const isBranch = roleAccount === 'Branch';
  const defaultDateSend = isBranch ? moment().subtract(1, 'weeks') : null;
  return (
    <CardMaterial
      title={<b style={{ color: '#11BEFF' }}>RINGKASAN SLA PENGIRIMAN</b>}
      style={{ borderRadius: '10px' }}
    >
      <Form
        initialValues={{
          typeFilter: dataFilterSla.typeFilter,
          datePeriode: dataFilterSla.datePeriode,
          branch: dataFilterSla.branch,
          customerId: dataFilterSla.customerId,
          customerProducts: dataFilterSla.customerProducts,
        }}
        validate={values => {
          const errors = {} as any;
          if (
            !values.datePeriode ||
            values.datePeriode.length !== 2 ||
            !values.datePeriode[0] ||
            !values.datePeriode[1]
          ) {
            errors.datePeriode = 'Tanggal Kirim wajib di isi';
          }
          return errors;
        }}
        onSubmit={handleSubmitFilter}
      >
        {({ form, handleSubmit, submitting, valid, values }) => {
          return (
            <div className={styles.wrapperFilter}>
              <div className={styles.wrapperItemFilter}>
                {!isBranch && (
                  <Field name="typeFilter">
                    {({ input }) => (
                      <RadioField
                        data={[
                          { value: 'Courier', label: 'Kurir' },
                          { value: 'Branch', label: 'Posko' },
                        ]}
                        onChange={value => {
                          handleChangeFilter('typeFilter', value);
                          input.onChange(value);
                        }}
                        value={input.value}
                      />
                    )}
                  </Field>
                )}
                <Row gutter={24}>
                  <Col md={12} className={styles.filterColumn}>
                    <Field name="datePeriode">
                      {({ input, meta }) => (
                        <InputDatePicker
                          label="Tanggal Kirim*"
                          firstPlaceholder="Pilih Tanggal Kirim"
                          secondPlaceholder="Sampai Tanggal"
                          format="YYYY-MM-DD"
                          type="range"
                          error={meta.error && meta.touched && meta.error}
                          onBlur={input.onBlur}
                          value={input.value || [null, null]}
                          onChange={value => input.onChange(value)}
                          onCalendarChange={value => input.onChange(value)}
                        />
                      )}
                    </Field>
                    {values.typeFilter === 'Courier' && (
                      <Field name="branch">
                        {({ input, meta }) => (
                          <SelectField
                            validate
                            label="Posko"
                            placeholder="Semua Posko"
                            data={dataAllBranch}
                            loading={isLoadingBranch}
                            disabled={
                              isBranch ||
                              !values.datePeriode ||
                              values.datePeriode.length !== 2 ||
                              !values.datePeriode[0] ||
                              !values.datePeriode[1]
                            }
                            value={input.value}
                            onChange={value => {
                              input.onChange(value);
                            }}
                            onBlur={value => {
                              input.onBlur(value);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            mode="multiple"
                            allowClear
                          />
                        )}
                      </Field>
                    )}
                  </Col>
                  <Col md={12} className={styles.filterColumn}>
                    <Field name="customerId">
                      {({ input, meta }) => (
                        <SelectField
                          validate
                          label="Pelanggan"
                          placeholder="Pilih Pelanggan"
                          data={listCustomer}
                          loading={isLoadingCustomer}
                          value={input.value}
                          onChange={value => {
                            input.onChange(value);
                            setSelectedCustomerId(value);
                            form.change('customerProducts', []);
                          }}
                          onBlur={value => {
                            input.onBlur(value);
                          }}
                          validateStatus={
                            meta.error && meta.touched ? 'error' : 'validating'
                          }
                          errorMessage={
                            meta.error && meta.touched && meta.error
                          }
                          allowClear
                        />
                      )}
                    </Field>
                    <Field name="customerProducts">
                      {({ input, meta }) => (
                        <SelectField
                          validate
                          label="Produk"
                          placeholder="Pilih Semua Produk"
                          data={listProduct}
                          loading={isLoadingProduct}
                          disabled={!values.customerId}
                          value={input.value}
                          onChange={value => {
                            input.onChange(value);
                          }}
                          onBlur={value => {
                            input.onBlur(value);
                          }}
                          validateStatus={
                            meta.error && meta.touched ? 'error' : 'validating'
                          }
                          errorMessage={
                            meta.error && meta.touched && meta.error
                          }
                          mode="multiple"
                          allowClear
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </div>
              <div className={styles.actionFilter}>
                <Button
                  text="Reset"
                  variant="danger"
                  className={styles.actionWidth}
                  onClick={() => {
                    handleResetFilter();
                    form.reset({
                      dateSend: defaultDateSend,
                      typeFilter: values.typeFilter,
                      //@ts-ignore
                      branch: [selectedBranchId],
                    });
                  }}
                  disabled={!valid}
                />
                <Button
                  text="Cari"
                  className={styles.actionWidth}
                  onClick={handleSubmit}
                  disabled={submitting || !valid}
                />
              </div>
            </div>
          );
        }}
      </Form>
    </CardMaterial>
  );
}

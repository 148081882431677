import React, { ReactNode } from 'react';
import { Modal } from 'antd';

interface ModalStatusProps {
  status: 'success' | 'error' | 'warning';
  title: string;
  content?: ReactNode;
  isReaload?: boolean;
  handleOke?: () => void;
}

const ModalStatus = ({
  status,
  title,
  content,
  isReaload = false,
  handleOke = () => undefined,
}: ModalStatusProps) => {
  return Modal[status]({
    title: (
      <p
        style={
          status === 'success' ? { color: '#31E116' } : { color: '#FF0000' }
        }
      >
        {title}
      </p>
    ),
    content,
    onOk() {
      handleOke();
      if (isReaload) {
        window.location.reload();
      }
    },
  });
};

export default ModalStatus;

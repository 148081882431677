import { Button, Col, Radio, Row } from 'antd';

import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import { Field, Form } from 'react-final-form';
import InputDatePicker from 'components/InputDatePicker';
import SelectField from 'components/SelectField';
import moment from 'moment-timezone';

export default function CourierBonusReportComponent(props: any) {
  const {
    setBranch,
    setCustomerId,
    listCustomer = [],
    listBranch = [],
    listCourier = [],
    listProducts = [],
    handleDownload,
    isLoading,
  } = props;
  const initialValues = React.useMemo(
    () => ({
      typeReportBonus: 'detail',
      startDate: null,
      endDate: null,
      courier: [],
      customerId: undefined,
      customerProductIds: [],
    }),
    []
  );
  return (
    <div style={{ paddingBottom: '35px' }}>
      <SpinMaterial
        spinning={isLoading}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b className="titleCard">INSENTIF KURIR</b>}
            style={{ borderRadius: '10px' }}
            content={
              <Form
                onSubmit={() => {}}
                initialValues={initialValues}
                validate={values => {
                  const { startDate, endDate, branch, courier } = values;
                  const errors: any = {};
                  if (!startDate) errors.startDate = 'Tanggal wajib diisi';
                  if (startDate)
                    if (!endDate) errors.endDate = 'Tanggal wajib diisi';
                  if (!branch) errors.branch = 'Posko wajib diisi';
                  if (courier?.length === 0)
                    errors.courier = 'Kurir wajib diisi';
                  return errors;
                }}
              >
                {(formProps: any) => {
                  const { values, invalid, pristine, form } = formProps;
                  const formatDate = 'YYYY-MM-DD';
                  const minDate = (d: any) =>
                    !d ||
                    d.isBefore('2020-01-01') ||
                    d.isAfter(
                      moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
                    );
                  const minDate2 = (d: any) =>
                    !d ||
                    d.isBefore(
                      moment(values.startDate).add(0, 'day').format(formatDate)
                    ) ||
                    d.isAfter(
                      moment(values.startDate).add(30, 'day').format(formatDate)
                    );

                  return (
                    <div>
                      <Row gutter={[36, 24]}>
                        <Col span={24}>
                          <Field name="typeReportBonus">
                            {({ input, meta }) => (
                              <Radio.Group
                                options={[
                                  { label: 'Detail', value: 'detail' },
                                  { label: 'Rekap', value: 'rekapitulasi' },
                                ]}
                                onChange={value => input.onChange(value)}
                                value={input.value}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col span={12}>
                          <div style={{ marginBottom: '24px' }}>
                            <Field name="startDate">
                              {({ input, meta }) => (
                                <InputDatePicker
                                  label="Pilih Tanggal"
                                  placeholder="Pilih tanggal"
                                  format={formatDate}
                                  error={
                                    meta.error && meta.touched && meta.error
                                  }
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  onChange={value => input.onChange(value)}
                                  onCalendarChange={value =>
                                    input.onChange(value)
                                  }
                                  disabledDate={minDate}
                                />
                              )}
                            </Field>
                          </div>
                          <div style={{ marginBottom: '24px' }}>
                            <Field name="branch">
                              {({ input, meta }) => (
                                <SelectField
                                  label="Posko"
                                  placeholder="Pilih Posko"
                                  data={listBranch}
                                  value={input.value}
                                  validate
                                  onChange={value => {
                                    setBranch(value);
                                    input.onChange(value);
                                  }}
                                  onBlur={value => input.onBlur(value)}
                                  validateStatus={
                                    meta.error && meta.touched
                                      ? 'error'
                                      : 'validating'
                                  }
                                  errorMessage={
                                    meta.error && meta.touched && meta.error
                                  }
                                />
                              )}
                            </Field>
                          </div>
                          <div>
                            <Field name="customerId">
                              {({ input, meta }) => (
                                <SelectField
                                  label="Pelanggan"
                                  placeholder="Pilih Pelanggan"
                                  data={listCustomer}
                                  value={input.value}
                                  validate
                                  onChange={value => {
                                    setCustomerId(value);
                                    input.onChange(value);
                                  }}
                                  onBlur={value => input.onBlur(value)}
                                  validateStatus={
                                    meta.error && meta.touched
                                      ? 'error'
                                      : 'validating'
                                  }
                                  errorMessage={
                                    meta.error && meta.touched && meta.error
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div style={{ marginBottom: '24px' }}>
                            <Field name="endDate">
                              {({ input, meta }) => (
                                <InputDatePicker
                                  label="Sampai Tanggal"
                                  placeholder="Pilih tanggal"
                                  format={formatDate}
                                  error={
                                    meta.error && meta.touched && meta.error
                                  }
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  onChange={value => input.onChange(value)}
                                  onCalendarChange={value =>
                                    input.onChange(value)
                                  }
                                  disabledDate={minDate2}
                                  disabled={!values.startDate}
                                />
                              )}
                            </Field>
                          </div>
                          <div style={{ marginBottom: '24px' }}>
                            <Field name="courier">
                              {({ input, meta }) => (
                                <SelectField
                                  label="Kurir"
                                  placeholder="Pilih Kurir"
                                  mode="multiple"
                                  disabled={!values.branch}
                                  allowClear
                                  data={[
                                    ...(Array.isArray(listCourier) &&
                                    listCourier?.length === input.value.length
                                      ? []
                                      : listCourier?.length !== 0
                                      ? [
                                          {
                                            id: 'SetAllCourier',
                                            name: 'Pilih Semua Kurir',
                                          },
                                        ]
                                      : []),
                                    ...(Array.isArray(listCourier)
                                      ? listCourier
                                      : []),
                                  ]}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  onChange={value => {
                                    if (
                                      value &&
                                      value.includes('SetAllCourier')
                                    ) {
                                      const allIds = listCourier?.map(
                                        (data: any) => data.id
                                      );
                                      return input.onChange(allIds);
                                    }
                                    return input.onChange(value);
                                  }}
                                  validate
                                  validateStatus={
                                    meta.error && meta.touched
                                      ? 'error'
                                      : 'validating'
                                  }
                                  errorMessage={
                                    meta.error && meta.touched && meta.error
                                  }
                                />
                              )}
                            </Field>
                          </div>
                          <div>
                            <Field name="customerProductIds">
                              {({ input, meta }) => (
                                <SelectField
                                  label="Produk"
                                  placeholder="Pilih Produk"
                                  mode="multiple"
                                  disabled={!values.customerId}
                                  data={[
                                    ...(Array.isArray(listProducts) &&
                                    listProducts?.length === input.value.length
                                      ? []
                                      : listProducts?.length !== 0
                                      ? [
                                          {
                                            id: 'SetAllProduct',
                                            name: 'Pilih Semua Produk',
                                          },
                                        ]
                                      : []),
                                    ...(Array.isArray(listProducts)
                                      ? listProducts
                                      : []),
                                  ]}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  onChange={value => {
                                    if (
                                      value &&
                                      value.includes('SetAllProduct')
                                    ) {
                                      const allIds = listProducts?.map(
                                        (data: any) => data.id
                                      );
                                      return input.onChange(allIds);
                                    }
                                    return input.onChange(value);
                                  }}
                                  validate
                                  validateStatus={
                                    meta.error && meta.touched
                                      ? 'error'
                                      : 'validating'
                                  }
                                  errorMessage={
                                    meta.error && meta.touched && meta.error
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className={'spacingDownloadButton'}>
                          <Button
                            type="danger"
                            shape="round"
                            size="default"
                            style={{
                              height: '35px',
                              width: '100px',
                              marginRight: '16px',
                            }}
                            disabled={pristine!}
                            onClick={() => {
                              form.reset();
                              setBranch(undefined);
                              setCustomerId(undefined);
                            }}
                          >
                            <b>Reset</b>
                          </Button>
                          <Button
                            type="primary"
                            shape="round"
                            size="default"
                            style={{
                              height: '35px',
                              width: '100px',
                            }}
                            disabled={invalid}
                            onClick={() => handleDownload(values)}
                          >
                            <b>Download</b>
                          </Button>
                        </div>
                      </Row>
                    </div>
                  );
                }}
              </Form>
            }
          />
        }
      />
    </div>
  );
}

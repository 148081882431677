import { UseBaseQueryOptions } from '@tanstack/react-query';
import instance from 'config/interceptor';

export const getDeliveryDataChangeAddress = async (
  params: UseBaseQueryOptions
) => {
  const [, waybill] = params.queryKey || [];

  const { data } = await instance.get(
    `Delivery/ChangeAddress?waybill=${waybill}`
  );
  return data;
};

export const mutateChangeDeliveryAddress = async (params: any) => {
  const { formatType } = params;
  return formatType === 'single'
    ? await instance.put(`Delivery/ChangeAddress`, params)
    : formatType === 'multiple'
    ? await instance.put(`Delivery/ChangeAddress/Upload`, params)
    : new Error('Invalid');
};

export const getDetailSyncDataDelivery = async (
  params: UseBaseQueryOptions
) => {
  const [, id, page, size] = params.queryKey || [];

  const { data } = await instance.get(
    `/SyncDataDelivery/${id}/${page}/${size}`
  );
  return data;
};

export const mutateUploadDownloadResult = async (params: any) => {
  return await instance.post(`Delivery/upload/downloadResult`, params);
};

export const mutateUploadSingleUpdateAddress = async (params: any) => {
  const { file } = params;
  let data = new FormData();
  data.append('file', file);
  return await instance.post(`Delivery/BulkChangeAddress`, data);
};

export const mutateUploadDownloadResultSingleUpdate = async (params: any) => {
  return await instance.get(`Delivery/BulkChangeAddress/${params}`);
};

export const mutateDownloadSampleFile = async () => {
  return await instance.get(`ExampleFiles/Unggah%20Patokan.xlsx`, {
    responseType: 'blob',
  });
};

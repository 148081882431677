import 'styles/ResendStyle/index.css';
import 'styles/ProductLogoStyle/index.css';

import { Button, Card, Col, Dropdown, Icon, Menu, Row, Tag } from 'antd';
import {
  EnumDeliveryType,
  EnumLabelSearchType,
} from 'core/enumerable/DeliveryType';

import ModalMaterial from 'materials/ModalMaterial';
import React from 'react';
import TrackingCardBarcodeContainer from 'module/DeliveryStatus/Container/TrackingCardBarcodeContainer';

interface IProps {
  CollapsListResend: any;
  CollapsKeyListResend: any;
  getListResend: any;
  getExportDeliveryStatus: any;
  getSearchTypeSubmit: any;
  typeToggle: any;
  visibleSignature: any;
  visibleImage: any;
  handleCollapsListResend: any;
  handleRemoveAllListResend: any;
  handleViewStatusDelivery: any;
  handleModalImage: any;
  handleModalSignature: any;
  handleCloseModal: any;
  handleSlectionExportPdf: any;
  handleExportPdf: any;
}
export default function DocumentInfoBarcodeComponent({
  CollapsListResend,
  CollapsKeyListResend,
  getListResend,
  getExportDeliveryStatus,
  getSearchTypeSubmit,
  typeToggle,
  visibleSignature,
  visibleImage,
  handleCollapsListResend,
  handleViewStatusDelivery,
  handleModalImage,
  handleModalSignature,
  handleCloseModal,
  handleSlectionExportPdf,
  handleExportPdf,
}: IProps) {
  const renderData = (label: any, content: any) => {
    return (
      <>
        <Col span={9}>
          <div
            style={{
              paddingBottom: '15px',
              color: '#969696',
            }}
          >
            <span>{label}</span>
          </div>
        </Col>
        <Col span={15}>
          <div
            style={{
              paddingBottom: '15px',
              color: '#000000',
            }}
          >
            <span>: {content}</span>
          </div>
        </Col>
      </>
    );
  };
  const styles = {
    cardBody: {
      maxHeight: '100%',
      overflow: 'auto',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    },
  };
  const menu = (
    <Menu onClick={handleSlectionExportPdf}>
      <Menu.Item key="waybillPelanggan">Waybill Pelanggan</Menu.Item>
      {/* <Menu.Item key="waybillKurir">Waybill Kurir</Menu.Item> */}
    </Menu>
  );
  const validateButtonPdf = getExportDeliveryStatus === null ? true : false;
  const placeholderFormPdf =
    getExportDeliveryStatus === null ? (
      <span style={{ color: '#969696', borderRadius: '15px' }}>
        Pilih Download
      </span>
    ) : getExportDeliveryStatus === 'waybillPelanggan' ? (
      'Waybill Pelanggan'
    ) : (
      'Waybill Kurir'
    );
  return (
    <div style={{ paddingTop: '20px', paddingBottom: '35px' }}>
      {getListResend
        .sort(function (a: any, b: any) {
          return b.index - a.index;
        })
        .map((data: any, index: any) => {
          let { Notes = '', ...address } = data?.shipToAddress
            ? JSON.parse(data?.shipToAddress)
            : {};
          let addressDetail =
            data?.shipToAddress === null
              ? ''
              : `${Object.values(address).join(' ')}`;
          return (
            <div>
              {typeToggle === 'signatureBarcode' ? (
                <ModalMaterial
                  className={''}
                  width={'40%'}
                  style={{ paddingTop: '20px' }}
                  teksTitle={'SIGNATURE'}
                  visible={visibleSignature}
                  onCancel={handleCloseModal}
                  content={
                    <div>
                      <div>
                        <img
                          className="image-gallery-slide"
                          alt="Signature"
                          src={`data:image/jpeg;base64,${data.signature}`}
                        />
                      </div>
                    </div>
                  }
                />
              ) : typeToggle === 'imageBarcode' ? (
                <ModalMaterial
                  className={''}
                  width={'40%'}
                  style={{ paddingTop: '20px' }}
                  teksTitle={'FOTO'}
                  visible={visibleImage}
                  onCancel={handleCloseModal}
                  content={
                    <div>
                      <img
                        className="image-gallery-slide"
                        alt="imagePhoto"
                        src={`data:image/jpeg;base64,${data.image}`}
                      />
                    </div>
                  }
                />
              ) : (
                ''
              )}

              <div className="header">
                <div
                  onClick={() =>
                    handleCollapsListResend(
                      index,
                      data.itemProcessTime,
                      data.itemDeliveryTime,
                      data.itemFinishedTime,
                      data.waybillCode
                    )
                  }
                >
                  <Row style={{ margin: '15px' }}>
                    <Col span={1}>
                      <div style={{ paddingTop: '4px' }}>
                        <Icon
                          type={
                            CollapsKeyListResend === index &&
                            CollapsListResend === true
                              ? 'down'
                              : 'right'
                          }
                        />
                      </div>
                    </Col>
                    <Col span={21}>
                      <div
                        style={{
                          fontSize: '1.2em',
                          color: 'black',
                          textAlign: 'center',
                        }}
                      >
                        {CollapsKeyListResend === index &&
                        CollapsListResend === true ? (
                          <b>
                            {getSearchTypeSubmit === 'barcode'
                              ? data.barcode +
                                '-' +
                                data.cycle +
                                '-' +
                                (data.deliveryStatus === 'Success'
                                  ? 'Diterima'
                                  : data.deliveryStatus === 'OnProcces'
                                  ? 'Proses'
                                  : data.deliveryStatus === 'OnHold'
                                  ? 'OnHold'
                                  : 'Dikembalikan')
                              : getSearchTypeSubmit === 'Name'
                              ? data.shipToName +
                                '-' +
                                data.cycle +
                                '-' +
                                (data.deliveryStatus === 'Success'
                                  ? 'Diterima'
                                  : data.deliveryStatus === 'OnProcces'
                                  ? 'Proses'
                                  : data.deliveryStatus === 'OnHold'
                                  ? 'OnHold'
                                  : 'Dikembalikan')
                              : data.valueFilter +
                                '-' +
                                data.cycle +
                                '-' +
                                (data.deliveryStatus === 'Success'
                                  ? 'Diterima'
                                  : data.deliveryStatus === 'OnProcces'
                                  ? 'Proses'
                                  : data.deliveryStatus === 'OnHold'
                                  ? 'OnHold'
                                  : 'Dikembalikan')}
                          </b>
                        ) : (
                          <span>
                            {getSearchTypeSubmit === 'barcode'
                              ? data.barcode +
                                '-' +
                                data.cycle +
                                '-' +
                                (data.deliveryStatus === 'Success'
                                  ? 'Diterima'
                                  : data.deliveryStatus === 'OnProcces'
                                  ? 'Proses'
                                  : data.deliveryStatus === 'OnHold'
                                  ? 'OnHold'
                                  : 'Dikembalikan')
                              : getSearchTypeSubmit === 'Name'
                              ? data.shipToName +
                                '-' +
                                data.cycle +
                                '-' +
                                (data.deliveryStatus === 'Success'
                                  ? 'Diterima'
                                  : data.deliveryStatus === 'OnProcces'
                                  ? 'Proses'
                                  : data.deliveryStatus === 'OnHold'
                                  ? 'OnHold'
                                  : 'Dikembalikan')
                              : data.valueFilter +
                                '-' +
                                data.cycle +
                                '-' +
                                (data.deliveryStatus === 'Success'
                                  ? 'Diterima'
                                  : data.deliveryStatus === 'OnProcces'
                                  ? 'Proses'
                                  : data.deliveryStatus === 'OnHold'
                                  ? 'OnHold'
                                  : 'Dikembalikan')}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                {CollapsKeyListResend === index &&
                CollapsListResend === true ? (
                  <div>
                    <hr
                      style={{
                        display: 'block',
                        height: '1px',
                        width: '100%',
                        border: '0',
                        borderTop: '1px solid #ccc',
                        margin: '0',
                        padding: '0',
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        paddingRight: '40px',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                      }}
                    >
                      {data.Status === 'notfound' ? (
                        <div
                          style={{
                            color: '#969696',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Tidak ditemukan
                        </div>
                      ) : (
                        <div style={{ fontSize: '1.0em' }}>
                          <Row>
                            <Col
                              span={12}
                              style={{
                                paddingLeft: '35px',
                                paddingRight: '15px',
                              }}
                            >
                              <Card
                                bodyStyle={styles.cardBody}
                                style={{
                                  borderRadius: '10px',
                                  height: '520px',
                                  maxHeight: '100%',
                                  width: '420px',
                                }}
                              >
                                <span
                                  style={{ color: '#11BEFF', fontSize: 15 }}
                                >
                                  <b>Informasi Dokumen</b>
                                </span>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    paddingTop: '10px',
                                  }}
                                >
                                  {data.customerProductImage === null ? (
                                    <div style={{ height: '50px' }}></div>
                                  ) : (
                                    <img
                                      className={'img'}
                                      height={'50px'}
                                      width={'75%'}
                                      alt="Product"
                                      src={`data:image/png;base64, ${data.customerProductImage}`}
                                    />
                                  )}
                                </div>
                                <Row style={{ paddingTop: '15px' }}>
                                  {renderData('Jenis Dokumen', data.itemType)}
                                </Row>
                                <Row>
                                  <Col span={9}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#969696',
                                      }}
                                    >
                                      <span>Waybill</span>
                                    </div>
                                  </Col>
                                  <Col span={15}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#000000',
                                      }}
                                    >
                                      <span
                                        onClick={() =>
                                          handleViewStatusDelivery(
                                            data.waybillCode
                                          )
                                        }
                                        style={{
                                          paddingTop: '40px',
                                          fontStyle: 'italic',
                                          textDecoration: 'underline',
                                          color: '#11BEFF',
                                        }}
                                      >
                                        {data.waybillCode}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>{renderData('Barcode', data.barcode)}</Row>
                                {data.filterType === 'Empty' ||
                                data.filterType === null ? (
                                  ''
                                ) : (
                                  <Row>
                                    {renderData(
                                      EnumLabelSearchType(data.filterType),
                                      data.valueFilter === null
                                        ? '-'
                                        : data.valueFilter
                                    )}
                                  </Row>
                                )}
                                <Row>
                                  {renderData(
                                    'Tipe Pengiriman',
                                    EnumDeliveryType(data.deliveryType)
                                  )}
                                </Row>
                                <Row>
                                  {renderData(
                                    'Kurir',
                                    data.courierCode === null
                                      ? '-'
                                      : data.courierCode +
                                          ' (' +
                                          data.courierName +
                                          ')'
                                  )}
                                </Row>
                                <Row>
                                  {renderData('Nama Pemilik', data.shipToName)}
                                </Row>
                                <Row>
                                  {renderData(
                                    'No Telp',
                                    data.shipToPhone === null ||
                                      data.shipToPhone === ''
                                      ? '-'
                                      : data.shipToPhone
                                  )}
                                </Row>
                                <Row>{renderData('Alamat', addressDetail)}</Row>
                                <Row>{renderData('Patokan Alamat', Notes)}</Row>
                                <Row>
                                  {renderData(
                                    'Lama Pengiriman',
                                    data.estimatedDelivery + ' Hari Kerja'
                                  )}
                                </Row>
                                <Row>
                                  <Col span={9}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#969696',
                                      }}
                                    >
                                      <span>Status</span>
                                    </div>
                                  </Col>
                                  <Col span={15}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#000000',
                                      }}
                                    >
                                      {/* <span>{data.deliveryStatus}</span> */}
                                      <Tag
                                        color={
                                          data.deliveryStatus === 'Success'
                                            ? 'green'
                                            : data.deliveryStatus ===
                                                'OnProcces' ||
                                              data.deliveryStatus === 'OnHold'
                                            ? 'gray'
                                            : 'red'
                                        }
                                      >
                                        {data.deliveryStatus === 'Success' ? (
                                          <span style={{ fontSize: '14px' }}>
                                            Diterima
                                          </span>
                                        ) : data.deliveryStatus ===
                                          'OnProcces' ? (
                                          <span style={{ fontSize: '14px' }}>
                                            Proses
                                          </span>
                                        ) : data.deliveryStatus === 'OnHold' ? (
                                          <span style={{ fontSize: '14px' }}>
                                            OnHold
                                          </span>
                                        ) : (
                                          <span style={{ fontSize: '14px' }}>
                                            Dikembalikan
                                          </span>
                                        )}
                                      </Tag>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  {renderData(
                                    'Produk',
                                    data.customerProductName
                                  )}
                                </Row>
                                {data.deliveryStatus === 'OnProcces' ||
                                data.deliveryStatus === 'OnHold' ? (
                                  ''
                                ) : (
                                  <Row>
                                    {renderData(
                                      data.deliveryStatus === 'Success'
                                        ? 'Nama Penerima'
                                        : data.deliveryStatus === 'Return' ||
                                          data.deliverStatus === 'Return3x'
                                        ? 'Alasan'
                                        : '',
                                      data.deliveryStatus === 'Success'
                                        ? data.receiverName +
                                            '/' +
                                            data.receiverTitle
                                        : data.deliveryStatus === 'Return' ||
                                          data.deliverStatus === 'Return3x'
                                        ? data.returnedReason
                                        : ''
                                    )}
                                  </Row>
                                )}
                                <Row>
                                  <Col span={9}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#969696',
                                      }}
                                    >
                                      <span>Foto</span>
                                    </div>
                                  </Col>
                                  <Col span={15}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#000000',
                                      }}
                                    >
                                      <span>
                                        {data.image === null ||
                                        data.image === '' ||
                                        data.image === undefined ? (
                                          '-'
                                        ) : (
                                          <Button
                                            type="link"
                                            onClick={handleModalImage}
                                          >
                                            Lihat Foto
                                          </Button>
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={9}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#969696',
                                      }}
                                    >
                                      <span>Tanda tangan</span>
                                    </div>
                                  </Col>
                                  <Col span={15}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#000000',
                                      }}
                                    >
                                      <span>
                                        {data.image === null ||
                                        data.image === '' ||
                                        data.image === undefined ? (
                                          '-'
                                        ) : (
                                          <Button
                                            type="link"
                                            onClick={handleModalSignature}
                                          >
                                            Lihat TTD
                                          </Button>
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={9}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#969696',
                                      }}
                                    >
                                      <span>Lihat lokasi</span>
                                    </div>
                                  </Col>
                                  <Col span={15}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#000000',
                                      }}
                                    >
                                      <span>
                                        {data.receiverLocation ||
                                        data.receiverLocationManual ? (
                                          <a
                                            href={
                                              'http://maps.google.com/maps?z=18&q=' +
                                              (data.receiverLocationManual ||
                                                data.receiverLocation ||
                                                '-')
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            <span>
                                              <span>
                                                {data.receiverLocationManual ||
                                                  data.receiverLocation ||
                                                  '-'}
                                              </span>
                                            </span>
                                          </a>
                                        ) : (
                                          <b>{'-'}</b>
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                <Row style={{ paddingTop: '35px' }}>
                                  <Col span={7}>
                                    <Dropdown
                                      overlay={menu}
                                      trigger={['click']}
                                    >
                                      <Button style={{ width: '160px' }}>
                                        <span>{placeholderFormPdf}</span>{' '}
                                        <Icon type={'right'} />
                                      </Button>
                                    </Dropdown>
                                  </Col>
                                  <Col
                                    span={17}
                                    style={{
                                      paddingTop: '1px',
                                      paddingLeft: '100px',
                                    }}
                                  >
                                    <Button
                                      onClick={handleExportPdf}
                                      disabled={validateButtonPdf}
                                      style={{
                                        paddingBottom: '3px',
                                        borderColor:
                                          getExportDeliveryStatus === null
                                            ? ''
                                            : '#7956EE',
                                        background:
                                          getExportDeliveryStatus === null
                                            ? ''
                                            : '#7956EE',
                                      }}
                                      type="primary"
                                      shape="circle"
                                      icon="download"
                                    ></Button>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                            <Col span={12} style={{ float: 'right' }}>
                              <TrackingCardBarcodeContainer index={index} />
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
    </div>
  );
}

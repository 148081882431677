import React from 'react';
import { Button, Col, Radio, Row } from 'antd';

import CardMaterial from '../../../../../materials/CardMaterial';
import SpinMaterial from '../../../../../materials/SpinMaterial';
import { Field, Form } from 'react-final-form';
import InputTextField from 'components/InputTextField';
import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import InputFile from 'components/InputFile';
import { getBase64 } from 'lib/util';

export default function UpdateAddressComponent(props: any) {
  const {
    handleSearchWaybill,
    setWaybill,
    dataDelivery,
    handleReset,
    refForm,
    handleSubmit,
    isLoading,
    listCustomer = [],
    listProducts = [],
    listTemplateUpload = [],
    setSelectedCustomerId,
    handleUploadSingleUpdate,
    handleDownloadSample,
  } = props;

  const shipToAddressData =
    dataDelivery && JSON.parse(dataDelivery?.shipToAddress);

  const renderSingleForm = (values: any, _form: any, invalid: boolean) => {
    return (
      <div>
        <Row>
          <Col span={12}>
            <div>
              <Field name="shipToName">
                {({ input, meta }) => (
                  <InputTextField
                    className="borderRadiusDefault"
                    label="Nama Pemilik"
                    placeholder="Masukkan Nama Pemilik"
                    value={input.value}
                    onChange={value => input.onChange(value)}
                    onBlur={value => input.onBlur(value)}
                    error={meta.error && meta.touched && meta.error}
                    width="100%"
                    disabled
                  />
                )}
              </Field>
            </div>
            <div style={{ marginTop: '24px' }}>
              <Field name="shipToPhone">
                {({ input, meta }) => (
                  <InputTextField
                    className="borderRadiusDefault"
                    label="No Telp"
                    placeholder="Masukkan nomor telepon"
                    value={input.value}
                    onChange={value => input.onChange(value)}
                    onBlur={value => input.onBlur(value)}
                    error={meta.error && meta.touched && meta.error}
                    width="100%"
                    maxLength={14}
                    disabled
                  />
                )}
              </Field>
            </div>
          </Col>
          <Col span={12} className={'spacingLeft'}>
            {shipToAddressData === undefined ? (
              <Field name="address">
                {({ input, meta }) => (
                  <InputTextField
                    className="borderRadiusDefault"
                    label="Alamat"
                    placeholder="Masukkan alamat"
                    value={input.value}
                    onChange={value => input.onChange(value)}
                    onBlur={value => input.onBlur(value)}
                    error={meta.error && meta.touched && meta.error}
                    width="100%"
                    maxLength={14}
                    disabled={!dataDelivery}
                  />
                )}
              </Field>
            ) : (
              <>
                {shipToAddressData &&
                  Object.keys(
                    Object.keys(shipToAddressData).reduce((obj, key) => {
                      if (key !== 'Notes') {
                        obj[key] = shipToAddressData[key];
                      }
                      return obj;
                    }, {})
                  ).map((key, index) => {
                    return (
                      <div
                        key={key}
                        style={{
                          marginTop: index === 0 ? '0px' : '24px',
                        }}
                      >
                        <Field name={`address.${key}`} key={key}>
                          {({ input, meta }) => (
                            <InputTextField
                              className="borderRadiusDefault"
                              label={key}
                              placeholder="Masukkan alamat"
                              value={input.value}
                              onChange={value => input.onChange(value)}
                              onBlur={value => input.onBlur(value)}
                              error={meta.error && meta.touched && meta.error}
                              width="100%"
                              maxLength={14}
                            />
                          )}
                        </Field>
                      </div>
                    );
                  })}
              </>
            )}
            <div style={{ marginTop: '24px' }}>
              <Field name="address.Notes">
                {({ input, meta }) => (
                  <InputTextField
                    className="borderRadiusDefault"
                    label="Patokan Alamat"
                    placeholder="Masukkan patokan alamat"
                    value={input.value}
                    onChange={value => input.onChange(value)}
                    onBlur={value => input.onBlur(value)}
                    error={meta.error && meta.touched && meta.error}
                    width="100%"
                    disabled={!dataDelivery}
                    maxLength={50}
                  />
                )}
              </Field>
            </div>
          </Col>
        </Row>
        {dataDelivery && (
          <Row style={{ marginTop: '24px' }}>
            <div className="spacingDownloadButton">
              <Button
                type="primary"
                shape="round"
                style={{
                  float: 'right',
                }}
                onClick={() => {
                  handleSubmit(values);
                }}
                disabled={isLoading || invalid}
              >
                Simpan
              </Button>
            </div>
            <div>
              <Button
                type="danger"
                shape="round"
                style={{
                  float: 'right',
                }}
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </Button>
            </div>
          </Row>
        )}
      </div>
    );
  };
  const renderSingleUploadForm = (
    values: any,
    form: any,
    _invalid: boolean
  ) => {
    return (
      <div>
        <Row
          gutter={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
          }}
        >
          <Col span={12}>
            <div>
              <Field name="fileName">
                {({ input, meta }) => {
                  return (
                    <InputFile
                      label="Unggah File Patokan Alamat (.xlsx)"
                      required
                      accept=".xlsx"
                      validateFile={meta.touched && meta.error && 'error'}
                      error={meta.error && meta.touched && meta.error}
                      handleChangeFile={async value => {
                        form.change('file', value.file.originFileObj);
                        form.change('fileName', value.file.name);
                      }}
                      value={input.value}
                      // disabled={!customFileId}
                    />
                  );
                }}
              </Field>
            </div>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              style={{
                top: '-4px',
                borderRadius: '20px',
              }}
              onClick={() => {
                handleUploadSingleUpdate(values);
              }}
              loading={isLoading}
              disabled={!values.file}
            >
              Simpan
            </Button>
          </Col>
        </Row>
        <Button
          type="link"
          style={{
            padding: 0,
          }}
          onClick={() => handleDownloadSample()}
        >
          <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
            Download Sample File
          </span>
        </Button>
      </div>
    );
  };
  const renderMultipleForm = (values: any, form: any, invalid: boolean) => {
    const { customerId, customFileId } = values;
    return (
      <div>
        <Row>
          <Col span={12}>
            <div>
              <div>
                <Field name="customerId">
                  {({ input, meta }) => (
                    <SelectField
                      label="Pelanggan"
                      placeholder="Pilih Pelanggan"
                      data={listCustomer}
                      value={input.value}
                      validate
                      onChange={value => {
                        setSelectedCustomerId(value);
                        input.onChange(value);
                      }}
                      onBlur={value => input.onBlur(value)}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                    />
                  )}
                </Field>
              </div>
              <div style={{ marginTop: '24px' }}>
                <Field name="customerProductId">
                  {({ input, meta }) => (
                    <SelectField
                      label="Produk"
                      placeholder="Pilih Produk"
                      data={listProducts}
                      value={input.value}
                      validate
                      onChange={value => input.onChange(value)}
                      onBlur={value => input.onBlur(value)}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      disabled={!customerId}
                    />
                  )}
                </Field>
              </div>
              <div style={{ marginTop: '24px' }}>
                <Field name="fileName">
                  {({ input, meta }) => {
                    const dataTemplateUpload = listTemplateUpload;
                    const selectedTemplateUpload =
                      dataTemplateUpload?.find(
                        value => value.id === values.customFileId
                      )?.name || null;
                    const lengthFormatFile =
                      selectedTemplateUpload?.length || 0;
                    let accceptFormat = '.csv';

                    if (selectedTemplateUpload) {
                      const result = selectedTemplateUpload.substring(
                        lengthFormatFile - 2,
                        lengthFormatFile
                      );

                      if (result === 'x)') {
                        accceptFormat = '.xlsx';
                      } else if (result === 't)') {
                        accceptFormat = '.txt';
                      } else if (result === 's)') {
                        accceptFormat = '.xls';
                      } else {
                        accceptFormat = '.csv';
                      }
                    }

                    return (
                      <InputFile
                        label="Unggah File"
                        required
                        accept={accceptFormat}
                        validateFile={meta.touched && meta.error && 'error'}
                        error={meta.error && meta.touched && meta.error}
                        handleChangeFile={async value => {
                          const base64: any = await getBase64(
                            value.file.originFileObj
                          );
                          form.change('fileBase64', base64.split(',')[1]);
                          form.change('fileName', value.file.name);
                        }}
                        value={input.value}
                        disabled={!customFileId}
                      />
                    );
                  }}
                </Field>
              </div>
            </div>
          </Col>
          <Col span={12} className={'spacingLeft'}>
            <div>
              <Field name="customFileId">
                {({ input, meta }) => (
                  <SelectField
                    label="Template Unggah"
                    placeholder="Pilih template unggah"
                    data={listTemplateUpload}
                    value={input.value}
                    validate
                    onChange={value => input.onChange(value)}
                    onBlur={value => input.onBlur(value)}
                    validateStatus={
                      meta.error && meta.touched ? 'error' : 'validating'
                    }
                    errorMessage={meta.error && meta.touched && meta.error}
                    disabled={!customerId}
                  />
                )}
              </Field>
            </div>
            <div style={{ marginTop: '24px' }}>
              <Field name="cycleDate">
                {({ input, meta }) => (
                  <InputDatePicker
                    label="Tanggal Cycle"
                    placeholder="Pilih tanggal"
                    format="DD-MM-YYYY"
                    error={meta.error && meta.touched && meta.error}
                    onBlur={input.onBlur}
                    value={input.value}
                    onChange={value => input.onChange(value)}
                    onCalendarChange={value => input.onChange(value)}
                    disabled={!customerId}
                  />
                )}
              </Field>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '24px' }}>
          <div className="spacingDownloadButton">
            <Button
              type="primary"
              shape="round"
              style={{
                float: 'right',
              }}
              onClick={() => {
                handleSubmit(values);
              }}
              disabled={isLoading || invalid}
            >
              Simpan
            </Button>
          </div>
        </Row>
      </div>
    );
  };

  const initialValues = {
    waybill: '',
    formatType: 'single',
    cycleDate: null,
  };

  return (
    <SpinMaterial spinning={isLoading} style={{}} size={'large'}>
      <React.Fragment>
        <Form
          initialValues={initialValues}
          onSubmit={() => {}}
          validate={values => {
            const errors = {} as any;
            const {
              formatType,
              waybill,
              customerId,
              customerProductId,
              cycleDate,
              customFileId,
              fileName,
            } = values;

            if (formatType === 'single') {
              if (!waybill) {
                errors.waybill = 'Waybill wajib diisi';
              }
            }
            if (formatType === 'multiple') {
              if (!customerId) {
                errors.customerId = 'Pelanggan wajib diisi';
              }
              if (customerId) {
                if (!customFileId) {
                  errors.customFileId = 'Template Unggah wajib diisi';
                }
                if (!customerProductId) {
                  errors.customerProductId = 'Produk wajib diisi';
                }
                if (!cycleDate) {
                  errors.cycleDate = 'Tanggal cycle wajib diisi';
                }
                if (!fileName) {
                  errors.fileName = 'File wajib diisi';
                }
              }
            }
            return errors;
          }}
        >
          {formProps => {
            refForm.current = formProps.form;
            const { values, form, invalid } = formProps;
            const { formatType, waybill } = values;
            return (
              <>
                <CardMaterial
                  extra={''}
                  title={<span className={'titleCard'}>PERBAHARUI ALAMAT</span>}
                  style={{}}
                  className={'borderRadiusDefault'}
                  content={
                    <React.Fragment>
                      <Row>
                        <div className="spacingBottom">
                          <Field name="formatType">
                            {({ input, meta }) => (
                              <Radio.Group
                                options={[
                                  { label: 'Satuan', value: 'single' },
                                  { label: 'Unggah', value: 'multiple' },
                                ]}
                                onChange={value => input.onChange(value)}
                                value={input.value}
                              />
                            )}
                          </Field>
                        </div>
                        {formatType === 'single' ? (
                          <>
                            <Col span={12}>
                              <div>
                                <Field name="waybill">
                                  {({ input, meta }) => (
                                    <InputTextField
                                      className="borderRadiusDefault"
                                      label="Waybill"
                                      placeholder="Masukan waybill"
                                      value={input.value}
                                      onChange={value => {
                                        setWaybill(value);
                                        input.onChange(value);
                                      }}
                                      onBlur={value => input.onBlur(value)}
                                      onPressEnter={() => {
                                        handleSearchWaybill();
                                      }}
                                      error={
                                        meta.error && meta.touched && meta.error
                                      }
                                      maxLength={30}
                                    />
                                  )}
                                </Field>
                              </div>
                            </Col>
                            <Col span={12}>
                              <Button
                                onClick={() => {
                                  handleSearchWaybill();
                                }}
                                style={{}}
                                className={'btnSubmitSearch'}
                                type="primary"
                                disabled={
                                  !waybill || waybill?.length < 15
                                    ? true
                                    : false
                                }
                              >
                                Cari
                              </Button>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </React.Fragment>
                  }
                />
                <CardMaterial
                  extra={''}
                  title={null}
                  style={{}}
                  className={'cardFormAddress'}
                  content={
                    formatType === 'single'
                      ? renderSingleForm(values, form, invalid)
                      : formatType === 'multiple'
                      ? renderMultipleForm(values, form, invalid)
                      : null
                  }
                />
                {formatType === 'single' && (
                  <CardMaterial
                    extra={''}
                    title={null}
                    style={{}}
                    className={'cardFormAddress'}
                    content={renderSingleUploadForm(values, form, invalid)}
                  />
                )}
              </>
            );
          }}
        </Form>
      </React.Fragment>
    </SpinMaterial>
  );
}

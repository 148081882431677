import {
  Radio,
  Form as FormANTD,
  Row,
  Col,
  Checkbox,
  Button,
  Icon,
} from 'antd';
import InputDatePicker from 'components/InputDatePicker';
import { InputText } from 'components/InputField';
import InputFile from 'components/InputFile';
import SelectField from 'components/SelectField';
import { treatmentDocument } from 'lib/constants';
import { getBase64 } from 'lib/util';
import CardMaterial from 'materials/CardMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import React from 'react';
import { Field, Form } from 'react-final-form';

export default function CustomerReportComponent(props: any) {
  const {
    isCollapse,
    handleCollapse,
    handleDownloadReport,
    isLoading,
    listCustomer = [],
    listProduct = [],
    listCustomReportFile = [],
    listCustomerSettingSuccess = [],
    listCustomerSettingReturn = [],
    setSelectedCustomerId,
    formRef,
  } = props;
  const initialValues = React.useMemo(
    () => ({
      filterDateType: 'Cycle',
      date: null,
      month: false,
      deliveryStats: ['OnProcces', 'Success', 'Return'],
      customerId: null,
      customerProducts: [],
      statusSuccess: [],
      statusReturn: [],
      customReportFileId: null,
      deliveryType: ['reguler'],
      groupFileName: false,
      deliveryDetail: false,
      today: false,
      fileName: '',
      coloumn: '',
      dataMatchingColumnType: 'Waybill',
      fileString: '',
      treatmentDocument: [],
    }),
    []
  );

  return (
    <div>
      <SpinMaterial size={'large'} spinning={isLoading}>
        <CardMaterial
          extra={''}
          title={<b className="titleCard">LAPORAN PELANGGAN</b>}
          style={{ borderRadius: '10px' }}
        >
          <Form
            onSubmit={() => {}}
            initialValues={initialValues}
            validate={values => {
              const {
                date,
                customerId,
                customReportFileId,
                customerProducts,
                deliveryStats,
                deliveryType,
                fileString,
                coloumn,
                filterDateType,
                treatmentDocument,
              } = values;
              const errors = {} as any;
              if (!date) errors.date = 'Tanggal Cycle Wajib Diisi';
              if (!customerId) errors.customerId = 'Pelanggan wajib diisi';
              if (!customReportFileId)
                errors.customReportFileId = 'Format Laporan wajib diisi';
              if (!customerProducts || customerProducts?.length === 0)
                errors.customerProducts = 'Produk pelanggan wajib diisi';
              if (!deliveryStats || deliveryStats?.length === 0)
                errors.deliveryStats = 'Status pengiriman wajib diisi';
              if (!deliveryType || deliveryType?.length === 0)
                errors.deliveryType = 'Tipe pengiriman wajib diisi';
              if (fileString && !coloumn)
                errors.coloumn = 'Index waybill wajib diisi';
              if (filterDateType === 'Manifest' && !treatmentDocument)
                errors.treatmentDocument = 'Perlakuan Dokumen wajib diisi';
              return errors;
            }}
          >
            {formProps => {
              const { form, values, invalid, pristine } = formProps;
              formRef.current = form;
              const {
                dataMatchingColumnType,
                month,
                filterDateType,
                customerId,
                customerProducts,
                deliveryStats,
                fileName,
                fileString,
              } = values;
              return (
                <FormANTD layout="vertical">
                  <Row style={{ marginBottom: '24px', padding: '0 8px' }}>
                    <Field
                      name="filterDateType"
                      type="radio"
                      value={filterDateType}
                    >
                      {({ input }) => (
                        <Radio.Group
                          options={[
                            { label: 'Cycle', value: 'Cycle' },
                            { label: 'Upload', value: 'Process' },
                            { label: 'Kirim', value: 'Delivery' },
                            { label: 'Selesai', value: 'Finish' },
                            { label: 'Manifest', value: 'Manifest' },
                          ]}
                          onChange={event => {
                            if (event.target.value === 'Manifest') {
                              form.change('deliveryStats', [
                                'Success',
                                'Return',
                              ]);
                              form.change(
                                'treatmentDocument',
                                treatmentDocument.map(item => item.id)
                              );
                            }
                            return input.onChange(event);
                          }}
                          value={input.value}
                        />
                      )}
                    </Field>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12} style={{ padding: 0 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '12px',
                        }}
                      >
                        <Col span={18}>
                          <Field name="date">
                            {({ input, meta }) => (
                              <InputDatePicker
                                label={`Tanggal ${
                                  filterDateType === 'Manifest'
                                    ? 'Scan Manifest'
                                    : filterDateType === 'Process'
                                    ? 'Upload'
                                    : filterDateType === 'Delivery'
                                    ? 'Kirim'
                                    : filterDateType === 'Finish'
                                    ? 'Selesai'
                                    : filterDateType
                                }`}
                                placeholder={`Pilih tanggal ${
                                  filterDateType === 'Manifest'
                                    ? 'Scan Manifest'
                                    : filterDateType === 'Process'
                                    ? 'Upload'
                                    : filterDateType === 'Delivery'
                                    ? 'Kirim'
                                    : filterDateType === 'Finish'
                                    ? 'Selesai'
                                    : filterDateType
                                }`}
                                format={'DD-MM-YYYY'}
                                error={meta.error && meta.touched && meta.error}
                                onBlur={input.onBlur}
                                value={input.value}
                                onChange={value => input.onChange(value)}
                                onCalendarChange={value =>
                                  input.onChange(value)
                                }
                                mode={month ? 'month' : 'date'}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col span={6}>
                          <Field name="month" type="checkbox">
                            {({ input }) => (
                              <Checkbox {...input} value={true}>
                                Bulanan
                              </Checkbox>
                            )}
                          </Field>
                        </Col>
                      </div>
                    </Col>
                    <Col span={12}>
                      {filterDateType === 'Manifest' ? (
                        <Field name="deliveryStats">
                          {({ input, meta }) => (
                            <SelectField
                              label="Jenis Manifest"
                              placeholder="Pilih Jenis Manifest"
                              value={input.value}
                              mode="multiple"
                              data={[
                                { id: 'Success', name: 'Sukses' },
                                { id: 'Return', name: 'Return' },
                              ]}
                              onBlur={input.onBlur}
                              onChange={value => input.onChange(value)}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                              validate
                              allowClear
                            />
                          )}
                        </Field>
                      ) : (
                        <Field name="deliveryStats">
                          {({ input, meta }) => (
                            <SelectField
                              label="Status Pengiriman"
                              placeholder="Status Pengiriman"
                              value={input.value}
                              mode="multiple"
                              data={[
                                { id: 'OnHold', name: 'OnHold' },
                                { id: 'OnProcces', name: 'Proses' },
                                { id: 'Success', name: 'Diterima' },
                                { id: 'Return', name: 'Dikembalikan' },
                              ]}
                              onBlur={input.onBlur}
                              onChange={value => input.onChange(value)}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                              validate
                              allowClear
                            />
                          )}
                        </Field>
                      )}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Field name="customerId">
                          {({ input, meta }) => (
                            <SelectField
                              label="Pelanggan"
                              placeholder="Pilih Pelanggan"
                              data={listCustomer}
                              value={input.value}
                              validate
                              onChange={value => {
                                setSelectedCustomerId(value);
                                input.onChange(value);
                              }}
                              onBlur={value => input.onBlur(value)}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col span={12}>
                        {filterDateType === 'Manifest' ? (
                          <Field name="treatmentDocument">
                            {({ input, meta }) => (
                              <SelectField
                                label="Perlakuan Dokumen"
                                placeholder="Pilih Perlakuan Dokumen"
                                value={input.value}
                                mode="multiple"
                                data={treatmentDocument}
                                onBlur={input.onBlur}
                                onChange={value => input.onChange(value)}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                                validate
                                allowClear
                              />
                            )}
                          </Field>
                        ) : (
                          <Field name="statusSuccess">
                            {({ input, meta }) => (
                              <SelectField
                                label="Status Diterima"
                                placeholder="Status Diterima"
                                value={input.value}
                                mode="multiple"
                                onBlur={input.onBlur}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                                data={[
                                  ...(Array.isArray(
                                    listCustomerSettingSuccess
                                  ) &&
                                  listCustomerSettingSuccess?.length ===
                                    input.value.length
                                    ? []
                                    : listCustomerSettingSuccess?.length !== 0
                                    ? [
                                        {
                                          id: 'SetAllStatus',
                                          name: 'Pilih Semua Status',
                                        },
                                      ]
                                    : []),
                                  ...(Array.isArray(listCustomerSettingSuccess)
                                    ? listCustomerSettingSuccess
                                    : []),
                                ]}
                                onChange={value => {
                                  if (value && value.includes('SetAllStatus')) {
                                    const allIds =
                                      listCustomerSettingSuccess?.map(
                                        (data: any) => data.id
                                      );
                                    return input.onChange(allIds);
                                  }
                                  return input.onChange(value);
                                }}
                                allowClear
                                disabled={!deliveryStats.includes('Success')}
                              />
                            )}
                          </Field>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Field name="customerProducts">
                          {({ input, meta }) => (
                            <SelectField
                              label="Produk"
                              placeholder="Pilih Produk"
                              mode="multiple"
                              disabled={!customerId}
                              data={[
                                ...(Array.isArray(listProduct) &&
                                listProduct?.length === input.value.length
                                  ? []
                                  : listProduct?.length !== 0
                                  ? [
                                      {
                                        id: 'SetAllProduct',
                                        name: 'Pilih Semua Produk',
                                      },
                                    ]
                                  : []),
                                ...(Array.isArray(listProduct)
                                  ? listProduct
                                  : []),
                              ]}
                              onBlur={input.onBlur}
                              value={input.value}
                              onChange={value => {
                                if (value && value.includes('SetAllProduct')) {
                                  const allIds = listProduct?.map(
                                    (data: any) => data.id
                                  );
                                  return input.onChange(allIds);
                                }
                                return input.onChange(value);
                              }}
                              validate
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col span={12}>
                        {filterDateType !== 'Manifest' && (
                          <Field name="statusReturn">
                            {({ input, meta }) => (
                              <SelectField
                                label="Status Dikembalikan"
                                placeholder="Status Dikembalikan"
                                value={input.value}
                                mode="multiple"
                                onBlur={input.onBlur}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                                data={[
                                  ...(Array.isArray(
                                    listCustomerSettingReturn
                                  ) &&
                                  listCustomerSettingReturn?.length ===
                                    input.value.length
                                    ? []
                                    : listCustomerSettingReturn?.length !== 0
                                    ? [
                                        {
                                          id: 'SetAllStatus',
                                          name: 'Pilih Semua Status',
                                        },
                                      ]
                                    : []),
                                  ...(Array.isArray(listCustomerSettingReturn)
                                    ? listCustomerSettingReturn
                                    : []),
                                ]}
                                onChange={value => {
                                  if (value && value.includes('SetAllStatus')) {
                                    const allIds =
                                      listCustomerSettingReturn?.map(
                                        (data: any) => data.id
                                      );
                                    return input.onChange(allIds);
                                  }
                                  return input.onChange(value);
                                }}
                                disabled={!deliveryStats.includes('Return')}
                                allowClear
                              />
                            )}
                          </Field>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Field name="customReportFileId">
                          {({ input, meta }) => (
                            <SelectField
                              label="Format Laporan"
                              placeholder="Pilih Format Laporan"
                              data={listCustomReportFile}
                              value={input.value}
                              validate
                              onChange={value => input.onChange(value)}
                              onBlur={value => input.onBlur(value)}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                              disabled={customerProducts.length === 0}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col span={12}>
                        {filterDateType !== 'Manifest' && (
                          <Field name="deliveryType">
                            {({ input, meta }) => (
                              <SelectField
                                label="Tipe Pengiriman"
                                placeholder="Tipe Pengiriman"
                                value={input.value}
                                mode="multiple"
                                data={[
                                  { id: 'reguler', name: 'Reguler' },
                                  { id: 'KU', name: 'Kirim Ulang' },
                                ]}
                                onBlur={input.onBlur}
                                onChange={value => input.onChange(value)}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                                allowClear
                              />
                            )}
                          </Field>
                        )}
                      </Col>
                    </Row>
                    {filterDateType !== 'Manifest' && (
                      <Row>
                        <Col span={12}></Col>
                        <Col span={12}>
                          <Row>
                            <Field name="groupFileName" type="checkbox">
                              {({ input }) => (
                                <Checkbox {...input} value={true}>
                                  File Name
                                </Checkbox>
                              )}
                            </Field>
                            <Field name="deliveryDetail" type="checkbox">
                              {({ input }) => (
                                <Checkbox {...input} value={true}>
                                  Data Lengkap
                                </Checkbox>
                              )}
                            </Field>
                            <Field name="today" type="checkbox">
                              {({ input }) => (
                                <Checkbox {...input} value={true}>
                                  To Day
                                </Checkbox>
                              )}
                            </Field>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </Row>

                  <Row>
                    <div style={{ float: 'left' }}>
                      <Button
                        type="link"
                        onClick={handleCollapse}
                        style={{ color: '#11BEFF', padding: 0 }}
                      >
                        Pencocokan Data
                      </Button>
                      <Icon
                        type={isCollapse === false ? 'right' : 'down'}
                        style={{ marginLeft: 5, fontSize: 10 }}
                      />
                    </div>
                  </Row>
                  {isCollapse && (
                    <Row gutter={16} style={{ marginTop: '8px' }}>
                      <Col span={12}>
                        <Field name="fileName">
                          {({ input, meta }) => {
                            return (
                              <InputFile
                                label="Cocokan data dengan (xls, csv)"
                                accept=".csv,.xls,.xlsx"
                                validateFile={
                                  meta.touched && meta.error && 'error'
                                }
                                error={meta.error && meta.touched && meta.error}
                                handleChangeFile={async value => {
                                  const base64: any = await getBase64(
                                    value.file.originFileObj
                                  );
                                  form.change(
                                    'fileString',
                                    base64.split(',')[1]
                                  );
                                  form.change('fileName', value.file.name);
                                }}
                                value={input.value}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Col span={12} style={{ marginRight: '16px' }}>
                          <Field name="coloumn">
                            {({ input, meta }) => (
                              <InputText
                                label={`${
                                  dataMatchingColumnType === 'ReferenceNumber'
                                    ? 'No. Referensi'
                                    : dataMatchingColumnType
                                } Terdapat Pada Kolom Ke -`}
                                placeholder="Masukkan nomor indeks kolom"
                                formStyle={{ margin: 0 }}
                                input={input}
                                meta={meta}
                                onChange={value => input.onChange(value)}
                                disabled={!fileName && !fileString}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col span={12}>
                          <Field name="dataMatchingColumnType">
                            {({ input, meta }) => (
                              <Radio.Group
                                options={[
                                  { label: 'Waybill', value: 'Waybill' },
                                  { label: 'Barcode', value: 'Barcode' },
                                  {
                                    label: 'No. Referensi',
                                    value: 'ReferenceNumber',
                                  },
                                ]}
                                onChange={value => input.onChange(value)}
                                value={input.value}
                              />
                            )}
                          </Field>
                        </Col>
                      </Col>
                    </Row>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      gap: '16px',
                      float: 'right',
                      marginTop: '8px',
                    }}
                  >
                    <Button
                      htmlType="reset"
                      type="danger"
                      disabled={pristine!}
                      onClick={() => {
                        form.reset();
                        setSelectedCustomerId(undefined);
                      }}
                      style={{ borderRadius: '24px' }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => handleDownloadReport(values)}
                      disabled={invalid}
                      style={{ borderRadius: '24px' }}
                    >
                      Download
                    </Button>
                  </div>
                </FormANTD>
              );
            }}
          </Form>
        </CardMaterial>
      </SpinMaterial>
    </div>
  );
}
